import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  ${({ theme: { pageMargins } }) => `
    margin-bottom: ${pageMargins?.sm}px;
  `}

  ${({ theme: { media, pageMargins } }) => media.md`
    margin-bottom: ${pageMargins?.md}px;
  `}
`

const Line = styled.div`
  display: none;

  ${({ theme: { media, colors, pageMargins } }) => media.md`
    display: block;
    height: 1px;
    width: 5%;
    max-width: 70px;
    background-color: ${colors?.webBlue};
    margin-right: ${pageMargins?.md}px;
    transform: translateY(10px);
  `}
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;

  ${({ theme: { colors, pageMargins } }) => `
    color: ${colors?.black};
    margin-bottom: ${pageMargins?.xs}px;
  `}

  & > h4 {
    font-weight: 600;
  }

  & > span {
    ${({ theme: { colors, pageMargins } }) => `
      color: ${colors?.webBlue};
      margin-right: ${pageMargins?.sm}px;
    `}
  }
`

const ItemDescription = styled.div`
  & > p {
    ${({ theme: { fontSize, colors, pageMargins } }) => `
      font-size: ${fontSize?.cvItem?.description?.mobile}px;
      color: ${colors?.black};
      margin-bottom: ${pageMargins?.xs}px;
    `}
  }

  & > a {
    ${({ theme: { pageMargins, colors, fontSize } }) => `
      font-size: ${fontSize?.cvItem?.description?.mobile}px;
      margin-top: ${pageMargins?.sm}px;
      color: ${colors?.darkGrey};
    `}
  }
`

const CvItem = ({ description, link, type, year }) => (
  <ItemWrapper>
    <Line />
    <DataContainer>
      <ItemTitle>
        <span>{year}</span>
        <h4>{type}</h4>
      </ItemTitle>

      <ItemDescription>
        <p>{description}</p>
        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        )}
      </ItemDescription>
    </DataContainer>
  </ItemWrapper>
)

CvItem.propTypes = {
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  type: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
}

CvItem.defaultProps = {
  link: null,
}

export default CvItem
