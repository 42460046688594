import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import parse from 'html-react-parser'
import theme from '../theme/theme'
import Layout from '../components/pageLayout/Layout'
import SEO from '../components/atoms/Seo'
import PageTitle from '../components/atoms/PageTitle'
import CvItem from '../components/atoms/CvItem'

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${({ theme: { media } }) => media.md`
    flex-direction: row;
  `}
`

const TitleContainer = styled.div`
  order: 2;

  ${({ theme: { media } }) => media.md`
    width: 65%;
    order: 1;
  `}
`

const Description = styled.div`
  line-height: 1.8em;

  ${({ theme: { colors, fontSize, pageMargins } }) => `
    padding: 0 ${pageMargins?.sm}px;
    font-size: ${fontSize.aboutDescription?.mobile}px;
    color: ${colors?.black};
    
    & > p {
      font-weight: 300;
      margin-bottom: ${pageMargins?.xs}px;
      
      & > strong {
        font-weight: 300;
        color: ${colors?.webBlue}
      }
    }
  `}

  ${({ theme: { media, fontSize } }) => media.md`
    font-size: ${fontSize.aboutDescription?.desktop}px;
  `}
`

const Image = styled(Img)`
  order: 1;
  width: 100%;
  height: 200px;

  ${({ theme: { media, pageMargins } }) => media.md`
    width: 30%;
    height: 100%;
    order: 2;
    margin: ${pageMargins?.sm}px ${pageMargins?.sm}px 0 0;
  `}
`

const CvContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme: { pageMargins, colors } }) => `
    margin-top: ${pageMargins?.sm}px;
    padding: ${pageMargins?.sm}px ${pageMargins?.sm}px;
    border-top: 2px solid ${colors?.webBlue};
  `}

  ${({ theme: { media, pageMargins } }) => media.md`
    border-top: none;
    margin-top: ${pageMargins?.md}px;
  `}
`

const SecondaryTitle = styled.h2`
  font-weight: 400;

  ${({ theme: { colors, maxWidths, pageMargins, fontSize } }) => `
    font-size: ${fontSize.pageTitle?.mobile}px;
    color: ${colors?.black};
    max-width: ${maxWidths?.content}px;
    padding-bottom: ${pageMargins?.sm}px;
  `}

  ${({ theme: { media, fontSize } }) => media.md`
    font-size: ${fontSize.pageTitle?.desktop}px;
  `}
`

const About = () => {
  const {
    wordpressPage: { acf: aboutData },
    allWordpressWpCv: { nodes: cvItems },
  } = useStaticQuery(graphql`
    {
      wordpressPage(wordpress_id: { eq: 170 }) {
        acf {
          page_title
          page_description
          profile_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }

      allWordpressWpCv(sort: { order: ASC, fields: acf___year }) {
        nodes {
          acf {
            description
            link
            type
            year
          }
          wordpress_id
        }
      }
    }
  `)

  const pageTitle = parse(aboutData.page_title)[0].props.children

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="About" />
        <DataContainer>
          <TitleContainer>
            <PageTitle titleText={`<h2>${pageTitle}</h2>`} />
            <Description>{parse(aboutData.page_description)}</Description>
          </TitleContainer>
          <Image
            fluid={aboutData.profile_image.localFile.childImageSharp.fluid}
            alt="Marta Castan"
          />
        </DataContainer>

        {/* <CvContainer>
          <SecondaryTitle>My CV</SecondaryTitle>

          {cvItems.map(({ acf, wordpress_id: wpId }) => (
            <CvItem
              key={wpId}
              description={acf.description}
              link={acf.link.length > 0 ? acf.link : null}
              type={acf.type}
              year={acf.year}
            />
          ))}
        </CvContainer> */}
      </Layout>
    </ThemeProvider>
  )
}

export default About
